
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Big from "bignumber.js";

import anime from "animejs";
import { BigNumber, padZeros } from "ethers/utils";
import { ethers } from "ethers";

@Component({})
export default class EggDisplay extends Vue {
    @Prop({required: true})
    readonly decimalPlace: number | undefined;

    @Prop({required: true})
    readonly eggPerShare: Big | undefined;

    @Prop({required: true})
    readonly eggPerBlock: Big | undefined;

    @Prop({required: true})
    readonly lastCalculatedBlock: Big | undefined;

    @Prop({ required: true })
    readonly staked: Big | undefined;

    @Prop({ required: true })
    readonly paid: Big | undefined;

    @Prop({ required: true })
    readonly startBlock: Big | undefined;

    @Prop({ required: true })
    readonly endBlock: Big | undefined;

    @Prop({ required: true })
    readonly totalNetworkStaked: Big | undefined;

    @Prop({ required: true })
    readonly currentBlock: Big | undefined;

    eggs = new Big(0);

    animatedVal: any;

    @Watch("currentBlock")
    onCurrentBlockChange() {
        this.calc();
    }

    @Watch("paid")
    onPaidChange() {
        this.calc();
    }

    mounted() {
        this.calc();
    }

    calc() {
        if (
            !(
                this.startBlock &&
                this.endBlock &&
                this.paid &&
                this.staked &&
                this.eggPerShare &&
                this.eggPerBlock &&
                this.currentBlock &&
                this.totalNetworkStaked &&
                this.lastCalculatedBlock
            )
        )
            return;

        let eggPerShare = this.eggPerShare.times(1);

        if (
            this.currentBlock.gt(this.startBlock) &&
            this.totalNetworkStaked.gt(0) &&
            this.currentBlock.gt(this.lastCalculatedBlock)
        ) {
            const s = this.lastCalculatedBlock.gt(this.startBlock) ? this.lastCalculatedBlock : this.startBlock;
            const n = this.currentBlock.lt(this.endBlock) ? this.currentBlock : this.endBlock;
            const myBlocks = n.minus(s)
            const reward = this.eggPerBlock.times(myBlocks);
            eggPerShare = eggPerShare.plus(reward.div(this.totalNetworkStaked));
        }

        const myShare = new Big(this.staked).div(this.totalNetworkStaked);

        const eggs = this.staked.times(eggPerShare).minus(this.paid);

        this.animatedVal = {
            v: this.eggs.times(`1e${this.decimalPlace}`).toNumber(),
        };

        anime({
            targets: this.animatedVal,
            v: eggs.times(`1e${this.decimalPlace}`).toNumber(),
            easing: "linear",
            round: 10,
            duration: 500,
            update: (v) => {
                this.eggs = new Big(this.animatedVal.v).div(
                    `1e${this.decimalPlace}`
                );
            },
        });
    }
}
