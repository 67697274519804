
import { JsonRpcProvider } from "ethers/providers";
import { Component, Prop, Watch } from "vue-property-decorator";
import Base from "./Base";
import Big from "bignumber.js";
import EggDisplay from "../components/egg-display.vue";
import Balance from "../components/balance.vue";

import { getContract } from "../lib/blockchain-helper";
import network from "../network";
import { BigNumber } from "ethers/utils";
import { Contract } from "ethers";
import { AddressZero } from "ethers/constants";

function toEther(v: any, dp = 18) {
    return new Big(v.toString()).div(`1e${dp}`);
}

@Component({
    components: { EggDisplay, Balance },
})
export default class SinglePool extends Base {
    @Prop()
    readonly pool:
        | {
              id: string;
              active: boolean;
              lpName: string;
              lpSymbol: string;
              lpAddress: string;
              weight: Big;
              startBlock: Big;
              endBlock: Big;
          }
        | undefined;

    @Prop()
    readonly account: string | undefined;

    @Watch("account")
    onAccountChanged() {
        this.loadUserStakeAtPool();
    }

    @Prop()
    readonly provider: JsonRpcProvider | undefined;

    @Prop()
    readonly weightTotal: Big | undefined;

    @Prop()
    readonly eggPerBlock: Big | undefined;

    @Prop()
    readonly blockNumber: Big | undefined;

    @Prop()
    readonly blockDateRelation:
        | {
              blockNumber: Big;
              date: number;
              blockSpeed: Big;
          }
        | undefined;

    myStake: {
        id: Big;
        amount: Big;
        paid: Big;
    } = {
        id: new Big(0),
        amount: new Big(0),
        paid: new Big(0),
    };

    swapTokenInfo = {
        symbol: "",
        dp: 0,
    };

    myLPValue = {
        major: new Big(0),
        minor: new Big(0),
    };

    ttLiquidityValue = new Big(0);
    swapper: Contract | undefined;
    farm: Contract | null = null;

    totalStaked = new Big(0);
    eggPerShare = new Big(0);
    lastCalculatedBlock = new Big(0);

    loading = 0;

    beforeDestroy() {
        this.cleanUp();
    }

    mounted() {
        ++this.loading;
        this.$nextTick(async function () {
            try {
                if (this.provider && this.pool) {
                    const farm = getContract(network, "Farms", this.provider);

                    const swapper = getContract(
                        network,
                        "Swapper",
                        this.provider,
                        this.pool.lpAddress
                    );

                    this.totalStaked = toEther(
                        await swapper.balanceOf(farm.address)
                    );

                    // The Egg is not a swapper, it is a token..
                    // this is the procedure to track the price of a token
                    if (this.pool.lpSymbol.includes("ELP")) {
                        // fetching the price..
                        const fetchLiquidity = async () => {
                            const liquidity = await swapper.currentLiquidity();
                            const major = toEther(liquidity[0]).times(2);
                            this.ttLiquidityValue = major;
                        };

                        fetchLiquidity();

                        this.provider.on(
                            swapper.address,
                            fetchLiquidity.bind(this)
                        );
                    }

                    this.swapper = swapper;
                    this.farm = farm;

                    swapper.on("Transfer", async (from: string, to: string) => {
                        if (
                            from == AddressZero ||
                            to == AddressZero ||
                            from == farm.address ||
                            to == farm.address
                        ) {
                            this.totalStaked = toEther(
                                await swapper.balanceOf(farm.address)
                            );
                        }
                    });

                    const staked = async (pid: BigNumber, who: string) => {
                        if (
                            this.farm &&
                            this.pool &&
                            who == this.account &&
                            pid.eq(this.pool.id)
                        ) {
                            // this stake is not recorded yet
                            if (this.myStake.id.lte(0)) {
                                const stakeId: BigNumber = await this.farm.stakeIds(
                                    pid,
                                    this.account
                                );
                                this.myStake.id = toEther(stakeId, 0);
                            }

                            const stakeInfo = await this.farm.stakes(
                                this.myStake.id.toString()
                            );

                            this.myStake.paid = toEther(stakeInfo.paid);
                            this.myStake.amount = toEther(stakeInfo.amount);

                            // update pool info
                            const poolInfo = await farm.pools(this.pool.id);

                            this.lastCalculatedBlock = toEther(
                                poolInfo.lastCalculatedBlock,
                                0
                            );
                            this.eggPerShare = toEther(poolInfo.eggPerShare);
                        }
                    };

                    farm.on("Staked", staked.bind(this));
                    farm.on("UnStaked", staked.bind(this));

                    // pool info
                    const poolInfo = await farm.pools(this.pool.id);

                    this.lastCalculatedBlock = toEther(
                        poolInfo.lastCalculatedBlock,
                        0
                    );
                    this.eggPerShare = toEther(poolInfo.eggPerShare);
                }

                return this.loadUserStakeAtPool();
            } catch (error) {
                let msg;
                if (error.data && error.data.message) msg = error.data.message;
                else {
                    msg = error.message || error.toString();
                }
                console.log("on mounted", msg);
            } finally {
                --this.loading;
            }
        });
    }

    async showLPValue() {
        if (this.pool && this.totalStaked.gt(0) && this.provider) {
            const win = window as any;
            win.$("#show-lp-value" + this.pool.lpSymbol).modal("show");

            const swapper = getContract(
                network,
                "Swapper",
                this.provider,
                this.pool.lpAddress
            );

            const value = await swapper.lpValue(
                this.myStake.amount.times(1e18).dp(0).toString()
            );

            if (this.swapTokenInfo.symbol == "") {
                const ercAddress = await swapper.usd();
                const erc20 = new Contract(
                    ercAddress,
                    [
                        "function decimals() public view returns (uint8)",
                        "function symbol() public view returns (string memory)",
                    ],
                    this.provider
                );

                const dp = await erc20.decimals();
                const symbol = await erc20.symbol();

                this.swapTokenInfo.symbol = symbol;
                this.swapTokenInfo.dp = dp;
            }

            this.myLPValue = {
                major: toEther(value[0].toString()),
                minor: toEther(value[1], this.swapTokenInfo.dp),
            };
        }
    }

    cleanUp() {
        if (this.swapper) {
            this.swapper.removeAllListeners("Transfer");
            this.provider?.removeAllListeners(this.swapper.address);
        }
    }

    apy() {
        if (this.pool && this.weightTotal && this.eggPerBlock) {
            const poolWeight = new Big(this.pool.weight);
            // const poolWeight = new Big(200);

            const poolEggPerBlock = poolWeight
                .div(this.weightTotal)
                .times(this.eggPerBlock);

            const ttPerEgg = this.$store.state.eggPrice;

            const yieldOneDay = poolEggPerBlock.times(86400).times(ttPerEgg);
            const yieldOneYear = yieldOneDay.times(365);

            if (this.ttLiquidityValue.gt(0)) {
                return yieldOneYear.div(this.ttLiquidityValue).times(100);
            }
        }

        return new Big(0);
    }

    loadUserStakeAtPool() {
        ++this.loading;

        this.$nextTick(async function () {
            try {
                if (!(this.pool && this.account && this.provider && this.farm))
                    return;

                const contract = this.farm;

                const pid = this.pool.id;

                const stakeId: BigNumber = await contract.stakeIds(
                    pid,
                    this.account
                );

                // console.log(this.pool.lpName, 'stake id:', stakeId.toString());

                if (stakeId.gt(0)) {
                    const stakeInfo = await contract.stakes(stakeId);

                    this.myStake.id = toEther(stakeId, 0);
                    this.myStake.paid = toEther(stakeInfo.paid);
                    this.myStake.amount = toEther(stakeInfo.amount);
                }
            } catch (error) {
                console.error(error);
            } finally {
                --this.loading;
            }
        });
    }

    claimEgg() {
        ++this.loading;
        this.$store.commit("blockchainProgress", "harvesting..");

        this.$nextTick(async function () {
            try {
                if (!(this.pool && this.provider)) throw "NO POOL";

                const poolId = this.pool.id;
                const p = this.getProvider(false);
                if (!p) return;

                const provider = p;
                const signer = provider.getSigner();

                try {
                    await signer.getAddress();
                } catch (error) {
                    throw "no account";
                }

                const farms = getContract(network, "Farms", signer);

                // const stakeId = await farms.stakeIds(poolId, account);
                // const t = await farms.calcEgg(stakeId);
                // const eggs = new Big(t.toString()).div(1e18);
                // console.log("should claim", eggs.toFormat());

                this.$store.commit(
                    "blockchainProgress",
                    `[${this.pool.lpName}] harvesting..`
                );
                const tx = await farms.doStake(poolId, 0);

                this.$store.commit(
                    "blockchainProgress",
                    `[${this.pool.lpName}] waiting for blockchain confirmation`
                );

                await this.provider.waitForTransaction(tx.hash);
            } catch (error) {
                let msg;
                if (error.data && error.data.message) msg = error.data.message;
                else {
                    msg = error.message || error.toString();
                }
                alert(msg);
            } finally {
                --this.loading;
                this.$store.commit("blockchainProgress", "");
            }
        });
    }

    estimateDateTimeFromBlock(block_: Big) {
        if (!this.blockDateRelation) return "";

        const block = new Big(block_.toString());

        if (block.isNaN()) return;

        const blocksSince = block.minus(this.blockDateRelation.blockNumber);
        const seconds = blocksSince.div(this.blockDateRelation.blockSpeed);
        const unix = seconds
            .times(1000)
            .dp(0)
            .plus(this.blockDateRelation.date)
            .toNumber();

        const date = new Date(unix);

        const d = date.getDate().toString().padStart(2, "0");
        const m = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Augt",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ][date.getMonth()];
        const h = date.getHours().toString().padStart(2, "0");
        const minu = date.getMinutes().toString().padStart(2, "0");

        return `${d} ${m} ${h}:${minu}`;
    }

    stake() {
        ++this.loading;
        this.$store.commit("blockchainProgress", "Farming...");

        this.$nextTick(async function () {
            if (!(this.provider && this.pool)) throw "no pool";
            if (!this.account) return alert("no account");

            const lpTokenAddress = this.pool.lpAddress;
            const poolId = this.pool.id;

            if (!lpTokenAddress) return alert("lp token address not defined");
            if (!poolId) return alert("pool not specified!");

            try {
                const p = this.getProvider(false);
                if (!p) return;

                const provider = p;
                const signer = provider.getSigner();
                let account;
                try {
                    account = await signer.getAddress();
                } catch (error) {
                    throw "no account";
                }

                const contract = getContract(network, "Farms", signer);

                const lpToken = getContract(
                    network,
                    "Swapper",
                    signer,
                    lpTokenAddress
                );

                const balance: BigNumber = await lpToken.balanceOf(account);

                if (balance.gt(0)) {
                    // console.log(balance.toString());

                    const allowance: BigNumber = await lpToken.allowance(
                        account,
                        contract.address
                    );

                    if (allowance.lt(balance)) {
                        this.$store.commit(
                            "blockchainProgress",
                            `[unlocking ${this.pool.lpName}] waiting permission for contract to spend LP Token`
                        );
                        const t = await lpToken.approve(
                            contract.address,
                            balance.mul("99999999")
                        );

                        this.$store.commit(
                            "blockchainProgress",
                            `[unlocked ${this.pool.lpName}] waiting for blockchain confirmation`
                        );
                        await t.wait(1);
                    }

                    this.$store.commit(
                        "blockchainProgress",
                        `waiting for staking confirmation`
                    );
                    const t = await contract.doStake(poolId, balance);

                    this.$store.commit(
                        "blockchainProgress",
                        `waiting for blockchain confirmation`
                    );

                    await this.provider.waitForTransaction(t.hash);
                } else {
                    throw "nothing to stake..";
                }
            } catch (error) {
                let msg;
                if (error.data && error.data.message) msg = error.data.message;
                else {
                    msg = error.message || error.toString();
                }
                alert(msg);
            } finally {
                --this.loading;
                this.$store.commit("blockchainProgress", "");
            }
        });
    }

    unstake() {
        ++this.loading;
        this.$store.commit("blockchainProgress", "Unfarming..");

        this.$nextTick(async function () {
            try {
                if (!(this.pool && this.provider)) throw "no pool";
                const poolId = this.pool.id;
                const p = this.getProvider(false);
                if (!p) return;

                const provider = p;
                const signer = provider.getSigner();

                let account;
                try {
                    account = await signer.getAddress();
                } catch (error) {
                    throw "no account";
                }

                const farms = getContract(network, "Farms", signer);

                // for debuging
                const stakeId = await farms.stakeIds(poolId, account);
                const stake = await farms.stakes(stakeId);

                // console.log(poolId);
                // console.log(stakeId.toString());
                // console.log(stake);

                this.$store.commit(
                    "blockchainProgress",
                    "waiting for unstaking confirmation"
                );
                const t = await farms.doUnStake(poolId, stake.amount);

                this.$store.commit(
                    "blockchainProgress",
                    "waiting for blockchain confirmation"
                );

                await this.provider.waitForTransaction(t.hash);
            } catch (error) {
                let msg;
                if (error.data && error.data.message) msg = error.data.message;
                else {
                    msg = error.message || error.toString();
                }
                alert(msg);
            } finally {
                --this.loading;
                this.$store.commit("blockchainProgress", "");
            }
        });
    }
}
