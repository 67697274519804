
import { BigNumber } from "ethers/utils";
import { Vue, Component, Watch } from "vue-property-decorator";
import Base from "./Base";

import { getContract, getProvider } from "../lib/blockchain-helper";
import ContractMonitor from "../lib/contract-monitor";

import Big from "bignumber.js";
import MixinMenu from "./_mixin_menu";
import network from "../network";
import { JsonRpcProvider } from "ethers/providers";
import SinglePool from "./_single_pool.vue";
import Balance from "../components/balance.vue";
import debounce from "lodash/debounce";
import { getFarms } from "./Adm.vue";

function convertTheFarmNumberToBig(data: any) {
  const converts = "weight,startBlock,endBlock".split(",").map((v) => v.trim());

  for (const idx in data.pools) {
    for (const convertKey of converts) {
      data.pools[idx][convertKey] = new Big(data.pools[idx][convertKey]);
    }
  }

  return data;
}

// this data is static, check out: /adm
const theFarm = convertTheFarmNumberToBig({
  weightTotal: new Big(10000),
  pools: [
    {
      id: "16",
      active: true,
      lpName: "EGG2-LP",
      lpSymbol: "ELPEGG2",
      lpAddress: "0x9744F1746BbF6A5A68470A50Dd3391cFC3e0061c",
      weight: 70,
      startBlock: 59475395,
      endBlock: 60684995,
    },
    {
      id: "17",
      active: true,
      lpName: "TT-USDT-LP",
      lpSymbol: "ELPTT-USDT",
      lpAddress: "0xF5cDF7f965Fb1Ae4E27fbA16AaDbA32195359138",
      weight: 15,
      startBlock: 59475395,
      endBlock: 60684995,
    },
    {
      id: "18",
      active: true,
      lpName: "IDRTB-LP",
      lpSymbol: "ELPIDRTB",
      lpAddress: "0x0528ba5bF75d02B259cA442cfdD2331a829EdE00",
      weight: 30,
      startBlock: 59475395,
      endBlock: 60684995,
    },
    {
      id: "19",
      active: true,
      lpName: "BUSD-LP",
      lpSymbol: "ELPBUSD",
      lpAddress: "0xec875Fe173e95c64E95dDE005dEb46d45733f4cC",
      weight: 20,
      startBlock: 59475395,
      endBlock: 60684995,
    },
    {
      id: "20",
      active: true,
      lpName: "BNB-LP",
      lpSymbol: "ELPBNB",
      lpAddress: "0xe0934D6b6d50124fcbAeEb96251560C6eA4b9d50",
      weight: 15,
      startBlock: 59475395,
      endBlock: 60684995,
    },
    {
      id: "21",
      active: true,
      lpName: "ETH-B-LP",
      lpSymbol: "ELPETH-B",
      lpAddress: "0xCCd54EfC1298565f949465B819f97bD7f67De9D1",
      weight: 20,
      startBlock: 59475395,
      endBlock: 60684995,
    },
    {
      id: "22",
      active: true,
      lpName: "BTC-B-LP",
      lpSymbol: "ELPBTC-B",
      lpAddress: "0xb5b6F02A4C37DE9Ab235f74Dac59C93dA536C3Aa",
      weight: 20,
      startBlock: 59475395,
      endBlock: 60684995,
    },
    {
      id: "23",
      active: true,
      lpName: "TT-ETH-LP",
      lpSymbol: "ELPTT-ETH",
      lpAddress: "0x458a9FE2E71e56e79205904AB132e3e32160250c",
      weight: 10,
      startBlock: 59475395,
      endBlock: 60684995,
    },
    {
      id: "24",
      active: true,
      lpName: "TT-WBTC-LP",
      lpSymbol: "ELPTT-WBTC",
      lpAddress: "0x2FCdef417dF4A274698e9f641c61b4FC2E216Dd1",
      weight: 10,
      startBlock: 59475395,
      endBlock: 60684995,
    },
    {
      id: "25",
      active: true,
      lpName: "NICER-LP",
      lpSymbol: "ELPNICER",
      lpAddress: "0x14aa2F18D0160ea76BFf76c141A8dFca328FE676",
      weight: 3,
      startBlock: 59475395,
      endBlock: 60684995,
    },
    {
      id: "26",
      active: true,
      lpName: "SET-LP",
      lpSymbol: "ELPSET",
      lpAddress: "0x16AB55c96596Cc926932AD3D71290D08ad932c09",
      weight: 3,
      startBlock: 59475395,
      endBlock: 60684995,
    },
    {
      id: "27",
      active: true,
      lpName: "RISER-LP",
      lpSymbol: "ELPRISER",
      lpAddress: "0xf406D475ABDAc65D45D9bCDbB356993D8D89b8Bf",
      weight: 3,
      startBlock: 59475395,
      endBlock: 60684995,
    },
    {
      id: "28",
      active: true,
      lpName: "CAREv4-LP",
      lpSymbol: "ELPCAREv4",
      lpAddress: "0x4381E8cdEd53a6182A37C85DFcCC9D252245e1C8",
      weight: 3,
      startBlock: 59475395,
      endBlock: 60684995,
    },
  ],
});

const previousFarms = convertTheFarmNumberToBig({
  weightTotal: new Big(10000),
  pools: [
    {
      id: "7",
      active: true,
      lpName: "EGG2-LP",
      lpSymbol: "ELPEGG2",
      lpAddress: "0x9744F1746BbF6A5A68470A50Dd3391cFC3e0061c",
      weight: 44,
      startBlock: 58555320,
      endBlock: 59160120,
    },
    {
      id: "8",
      active: true,
      lpName: "TT-USDT-LP",
      lpSymbol: "ELPTT-USDT",
      lpAddress: "0xF5cDF7f965Fb1Ae4E27fbA16AaDbA32195359138",
      weight: 17,
      startBlock: 58555320,
      endBlock: 59160120,
    },
    {
      id: "9",
      active: true,
      lpName: "IDRTB-LP",
      lpSymbol: "ELPIDRTB",
      lpAddress: "0x0528ba5bF75d02B259cA442cfdD2331a829EdE00",
      weight: 30,
      startBlock: 58555320,
      endBlock: 59160120,
    },
    {
      id: "10",
      active: true,
      lpName: "BUSD-LP",
      lpSymbol: "ELPBUSD",
      lpAddress: "0xec875Fe173e95c64E95dDE005dEb46d45733f4cC",
      weight: 17,
      startBlock: 58555320,
      endBlock: 59160120,
    },
    {
      id: "11",
      active: true,
      lpName: "BNB-LP",
      lpSymbol: "ELPBNB",
      lpAddress: "0xe0934D6b6d50124fcbAeEb96251560C6eA4b9d50",
      weight: 17,
      startBlock: 58555320,
      endBlock: 59160120,
    },
    {
      id: "12",
      active: true,
      lpName: "ETH-B-LP",
      lpSymbol: "ELPETH-B",
      lpAddress: "0xCCd54EfC1298565f949465B819f97bD7f67De9D1",
      weight: 20,
      startBlock: 58555320,
      endBlock: 59160120,
    },
    {
      id: "13",
      active: true,
      lpName: "BTC-B-LP",
      lpSymbol: "ELPBTC-B",
      lpAddress: "0xb5b6F02A4C37DE9Ab235f74Dac59C93dA536C3Aa",
      weight: 20,
      startBlock: 58555320,
      endBlock: 59160120,
    },
    {
      id: "14",
      active: true,
      lpName: "TT-ETH-LP",
      lpSymbol: "ELPTT-ETH",
      lpAddress: "0x458a9FE2E71e56e79205904AB132e3e32160250c",
      weight: 17,
      startBlock: 58555320,
      endBlock: 59160120,
    },
    {
      id: "15",
      active: true,
      lpName: "TT-WBTC-LP",
      lpSymbol: "ELPTT-WBTC",
      lpAddress: "0x2FCdef417dF4A274698e9f641c61b4FC2E216Dd1",
      weight: 17,
      startBlock: 58555320,
      endBlock: 59160120,
    },
  ],
});

@Component({
  mixins: [MixinMenu],
  components: { SinglePool, Balance },
})
export default class Farm extends Base {
  provider: JsonRpcProvider | null = null;

  pools = theFarm.pools;
  previousPools: any[] = [];
  tab = "running";
  previousFarmsLoaded = false;

  @Watch("tab")
  onTabChanged() {
    // to prevent loading inactive pool at the startup
    if (this.tab == "previous" && this.previousFarmsLoaded == false) {
      this.previousFarmsLoaded = true;
      this.previousPools = previousFarms.pools;
    }
  }

  weightTotal = theFarm.weightTotal;

  loading = false;

  blockNumber = new Big(0);
  blockSpeeds: Big[] = [];
  blockSpeed = new Big(0);
  blockPerYear = new Big(0);

  blockDateRelation = {
    blockNumber: new Big(0),
    date: Date.now(),
    blockSpeed: new Big(0),
  };

  cleanUp() {
    this.provider?.removeAllListeners();
  }

  beforeDestroy() {
    this.cleanUp();
  }

  eggTokenAddress = "";
  eggPerBlock = new Big(0);

  async mounted() {
    // provider will exist from this point forward..
    // because it is a monitoring provider..
    this.provider = this.getProvider(true);

    if (!this.provider) throw "could not create a monitor provider";

    if (network == "development") {
      this.pools = [];

      let farms = await getFarms(this.provider);

      if (farms) {
        farms = convertTheFarmNumberToBig(farms);
        this.weightTotal = farms.weightTotal;
        this.pools = farms.pools;
      }
    }

    const egg = getContract(network, "EGG", this.provider);

    const eggPerBlock: BigNumber = await egg.eggPerBlock();

    this.eggTokenAddress = egg.address;
    this.eggPerBlock = new Big(eggPerBlock.toString()).div(1e18);

    // monitoring block tick..
    let lastTick = 0;

    const whenBlockChange = (blockNumber: BigNumber) => {
      if (lastTick > 0) {
        const span = new Big(blockNumber.toString()).minus(this.blockNumber);

        const ellapsed = Date.now() - lastTick;
        const speed = span.div(ellapsed).times(1000);

        this.blockSpeeds.unshift(speed);
        this.blockSpeeds.splice(50);

        const sum = this.blockSpeeds.reduce((p, v) => {
          return p.plus(v);
        }, new Big(0));

        this.blockSpeed = sum.div(this.blockSpeeds.length).dp(2);

        this.blockPerYear = this.blockSpeed.times(86400).times(365);

        if (
          this.blockSpeeds.length >= 3 &&
          this.blockDateRelation.blockSpeed.eq(0)
        ) {
          this.blockDateRelation.blockNumber = new Big(blockNumber.toString());
          this.blockDateRelation.blockSpeed = this.blockSpeed.times(1);
          this.blockDateRelation.date = Date.now();
        }
      }

      this.blockNumber = new Big(blockNumber.toString());
      lastTick = Date.now();
    };

    this.provider.on("block", debounce(whenBlockChange.bind(this), 1000));
  }
}
